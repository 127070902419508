import { Capacitor } from '@capacitor/core';
import { logFacebook } from '@src/facebookLogging';
import ky from 'ky';
import posthog from 'posthog-js';
import { backendUrl, searchParamResetQueryCache } from './constants';
import { getTokens, refreshToken, signOut } from './identityService';
import { currentVersions } from './versioning';

export const instance = ky.create({
  prefixUrl: backendUrl,
  searchParams: {
    currentTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
  hooks: {
    beforeRequest: [
      async (request) => {
        request.headers.set('frontend-app-version', currentVersions.version);
        request.headers.set('ngrok-skip-browser-warning', 'true'); // https://stackoverflow.com/questions/40327672/configure-ngroks-cors-headers#comment130039752_51879301
        const tokens = await getTokens();
        if (tokens) {
          request.headers.set('Authorization', `Bearer ${tokens.accessToken}`);
        }
      },
    ],
    beforeError: [
      async (error) => {
        if (error.response.status === 401) {
          const json = await error.response.json();
          if (json.errorCode === 'auth/user-not-found') {
            signOut();
          } else {
            refreshToken();
          }
        }
        return error;
      },
    ],
  },
});

interface PaginatedData<T> {
  data: T[];
  nextCursor?: any;
  totalCount: number;
  filteredCount: number;
}

export interface Feeling {
  name: string;
}

export interface Entry {
  id: string;
  updatedAt: string;
  isEmpty: boolean;
  status: 'Completed' | 'Open';
  type:
    | 'Cbt'
    | 'Pp'
    | 'ExposureFear'
    | 'ExposureExperiment'
    | 'ExposureReflection';
  description: string;
  challengeFeelings: Feeling[];
  createdAt: string;
  displayTitle: string;
  title: string;
  initialSudHecto: number | null;
  thoughtsText: string;
  behaviorsText: string;
  challengeBehaviorsText: string;
  challengeThoughtsText: string;
  closingSudHecto: number | null;
  latestTask?: Task;
  tasks?: Task[];
  thinkingTraps: ThinkingTrap[];
  allThinkingTraps: ThinkingTrap[];

  promptId: string;
  promptIdToQuestionText: Record<string, string>;
  responseText: string;
  effectAnswer?: 'Worse' | 'Same' | 'Better';

  feelings: Feeling[];

  fearAvoidance: string;
  fearAvoidanceImpact: string;
  fearedSituation: string;
  fearAvoidanceOther: string;
  fearedOutcome: string;

  experimentScenario: string;
  experimentExpectancyRating: number;
  experimentSeverityRating: number;
  experimentDanger: 'Dangerous' | 'Safe' | 'Unselected';
  experimentEvidence: string;
  expectancyRatings: number[];

  reflectionStartingExpectancyRating?: number;
  reflectionFearedOutcomeOccurrence: 'Occurred' | 'DidNotOccur' | 'Unselected';
  reflectionFearedOutcomeOccurrenceEvidence: string;
  reflectionSeverityRating: number;
  reflectionText: string;
  reflectionLearnings: string;
  reflectionExpectancyRating: number;

  exposureExperiments: Entry[];
  exposureReflections: Entry[];

  exposureFear: Entry;
  exposureExperiment: Entry;
}
export const getEntries = async ({
  limit,
  nextCursor,
  type,
  status,
  startTime,
}: {
  limit?: number;
  nextCursor?: string;
  type?: Entry['type'];
  status?: Entry['status'];
  startTime?: string;
} = {}): Promise<PaginatedData<Entry>> =>
  instance
    .get('entries', {
      searchParams: JSON.parse(
        JSON.stringify({
          limit,
          nextCursor,
          type,
          status,
          startTime,
        }),
      ),
    })
    .json();

export const getThinkingTrapsTable = async (): Promise<{
  countCompletedEntries: number;
  thinkingTrapsOrderedByRatios: (ThinkingTrap & {
    ratiosByMonth: { ratio: number; startDate: string }[];
    hasSomeActivity: boolean;
  })[];
  thinkingTrapsOrderedByRatiosSkeleton: (ThinkingTrap & {
    ratiosByMonth: { ratio: number; startDate: string }[];
    hasSomeActivity: boolean;
  })[];
}> => instance.get('entries/thinkingTrapsTable').json();

export const createEntry = async (data: {
  type: Entry['type'];
  exposureFearId?: string;
}): Promise<Entry> => {
  posthog.capture('Entry Created', { type: data.type });
  logFacebook('EntryCreated', true);
  return instance.post('entries', { json: data }).json();
};

export const getEntry = async (id: string): Promise<Entry> =>
  instance.get(`entries/${id}`).json();

export const updateEntry = async (
  id: string,
  data: Partial<Entry>,
): Promise<Entry> => instance.patch(`entries/${id}`, { json: data }).json();

export const deleteEntry = async (
  id: string,
  ensureEmpty?: boolean,
): Promise<void> =>
  instance.delete(`entries/${id}`, { json: { ensureEmpty } }).json();

export const deleteEntryIfEmpty = async (
  id: string,
): Promise<{ wasDeleted: boolean }> =>
  instance.post(`entries/${id}/deleteIfEmpty`).json();

export type TaskType =
  | 'InitialInfo'
  | 'InitialSud'
  | 'CognitiveTriangleSelection'
  | 'CollectBehaviors'
  | 'CollectFeelings'
  | 'CollectThoughts'
  | 'DecideToChallenge'
  | 'ChallengeIntro'
  | 'ThinkingTraps'
  | 'ChallengeThoughts'
  | 'Reflect'
  | 'ChallengeFeelings'
  | 'ChallengeBehaviors'
  | 'ClosingSud'
  | 'Closing'
  | 'PpPrompt'
  | 'PpFeelings'
  | 'PpClosing'
  | 'ExposureFearIntro'
  | 'ExposureFearAvoidance'
  | 'ExposureFearSituation'
  | 'ExposureFearExplore'
  | 'ExposureFearOutcome'
  | 'ExposureExperimentIntro'
  | 'ExposureExperimentDesign'
  | 'ExposureExperimentSeverity'
  | 'ExposureExperimentExpectancy'
  | 'ExposureReflectionConduct'
  | 'ExposureReflectionSection'
  | 'ExposureReflectionOutcome'
  | 'ExposureReflectionPause'
  | 'ExposureReflectionReflect'
  | 'ExposureReflectionReassess'
  | 'ExposureReflectionClosing';

export interface Task {
  id: string;
  type: TaskType;
  previousTask?: Task;
}

export interface ThinkingTrap {
  id: string;
  name: string;
  description: string;
  isSelected?: boolean;
}

export interface LoadTaskData {
  progressPercent: number;
  isProgressGradient?: boolean;
  isInitialEntry?: boolean;
  primaryCtaText?: string;
  secondaryCtaText?: string;
  task: Task;
  entry: Entry;
  taskHistory: Task[];
}

export const loadTask = async (params: {
  entryId: string;
  taskType?: string;
}): Promise<LoadTaskData> =>
  instance.get('tasks/load', { searchParams: params }).json();

export const completeTask = async (data: {
  entryId: string;
  taskType: string;
  formData: unknown;
}): Promise<{ nextTask?: Task; loadedNextTask?: LoadTaskData }> =>
  instance.post('tasks/complete', { json: data }).json();

export const createFeeling = async (name: string): Promise<Feeling> =>
  instance.put('feelings', { json: { name } }).json();

export const searchFeelings = async (
  query: string,
  purpose?: string,
): Promise<Feeling[]> =>
  instance
    .get('feelings/search', {
      searchParams: JSON.parse(JSON.stringify({ query, purpose })),
    })
    .json();

export interface IdentityUser {
  email: string;
}
type ReasonToUseType =
  | 'Anxiety'
  | 'Depression'
  | 'ADHD'
  | 'Trauma'
  | 'OCD'
  | 'Other'
  | 'NotSure';

interface ProfessionalAccess {
  status: 'Pending' | 'Completed';
  professionalRole: string;
  professionalRoleOther: string;
  name: string;
  email: string;
  organization: string;
}

export interface User {
  id: string;
  idHashed: string;
  createdAt: string;
  lastActivityAt: string;
  lastReviewPromptAttemptAt?: string;
  shouldShowOnboarding: boolean;
  shouldShowPaywall: boolean;
  shouldShowFirstTimePrompt: boolean;
  shouldHideThinkingTrapDescriptions?: boolean;
  shouldShowFeatureStatusToggle: boolean;
  shouldDisplayEntrySavingStatus: boolean;
  colorMode: 'Light' | 'Dark' | 'System';
  hasSeenFirstEntryCompletedPrompt: boolean;
  hasCompletedExposureOnboarding: boolean;
  onboardingSudHecto?: number;
  voiceDictationEducationPromptSeenAt?: string;
  accountPromptSeenAt?: string;
  surveyPromptSeenAt?: string;
  hasDismissedGettingStartedChecklist: boolean;
  hasCompletedGettingStartedChecklist: boolean;
  isGoalPushNotificationsOn: boolean;
  isAssessmentReminderPushNotificationsOn: boolean;
  reasonsToUseTypes: ReasonToUseType[];
  reasonsToUseDetails: string;
  overallIssues: string;
  outlookAfterOverallIssuesResolved: string;
  goalEntryDayCount?: null | number;
  isUserUs?: boolean;
  referrerId: null | string;
  professionalAccess?: ProfessionalAccess;
}
interface Entitlement {
  expiresDate: string;
  productIdentifier: string;
  periodType: 'intro' | 'normal' | 'trial';
  store:
    | 'app_store'
    | 'promotional'
    | 'play_store'
    | 'mac_app_store'
    | 'stripe';
}
export interface Subscription {
  expiresDate: string;
  periodType: 'intro' | 'normal' | 'trial';
  store:
    | 'app_store'
    | 'promotional'
    | 'play_store'
    | 'mac_app_store'
    | 'stripe';
}
export interface Subscriber {
  hasAccess: boolean;
  managementUrl: string | null;
  entitlements: {
    access?: Entitlement;
  };
  activeEntitlements: {
    access?: Entitlement;
  };
  subscriptions: Record<string, Subscription>;
}
export interface Me {
  identityUser?: IdentityUser;
  user: User;
  subscriber?: Subscriber;
  isUserProbablyTester: boolean;
}
export const getMe = async (): Promise<Me> => instance.get('users/me').json();

export const getMeMetadata = async (): Promise<any> =>
  instance.get('users/me/metadata').json();

export const updateMe = async (
  data: Partial<User>,
): Promise<{
  identityUser: IdentityUser;
  user: User;
}> => {
  if (data.goalEntryDayCount) {
    posthog.capture('Update goal', {
      goaltEntryDayCount: data.goalEntryDayCount,
    });
    logFacebook('GoalUpdated', true);
  }
  return instance.patch('users/me', { json: data }).json();
};

export const getGettingStartedChecklist = async (): Promise<{
  isCompleted: boolean;
  isDismissed: boolean;
  details: {
    id: string;
    isCompleted: boolean;
    text: string;
    description: string;
    timeEstimate: string;
  }[];
}> => instance.get('users/me/gettingStartedChecklist').json();

export interface QuestionnaireInstance {
  createdAt: string;
  results: Record<
    'phq9' | 'gad7',
    {
      equalizedPercentScore: number;
      severity: string;
    }
  >;
}

export const getQuestionnaire = async (): Promise<{
  questions: {
    id: string;
    text: string;
  }[];
}> =>
  instance
    .get('questionnaires', {
      searchParams: {
        type: 'phqAds',
      },
    })
    .json();

export const getQuestionnaires = async ({
  limit,
}: {
  limit: number;
}): Promise<Omit<PaginatedData<QuestionnaireInstance>, 'nextCursor'>> => {
  return instance
    .get('questionnaireInstances', {
      searchParams: {
        type: 'phqAds',
        limit,
      },
    })
    .json();
};

export const getQuestionnairesChart = async (): Promise<{
  severityLabels: string[];
  questionnaires: QuestionnaireInstance[];
}> =>
  instance
    .get('questionnaireInstances/chart', {
      searchParams: {
        type: 'phqAds',
      },
    })
    .json();

export const getQuestionnairesResult = async ({
  shouldExcludeSelfHarm = false,
}: { shouldExcludeSelfHarm?: boolean }): Promise<{
  heading: string;
  text: string;
  highlightedAction: {
    action: 'PpEntry' | 'CbtEntry' | 'EmergencyResources';
    mainText: string;
    subText: string;
  } | null;
}> =>
  instance
    .get('questionnaireInstances/result', {
      searchParams: {
        type: 'phqAds',
        shouldExcludeSelfHarm: shouldExcludeSelfHarm?.toString(),
      },
    })
    .json();

export const submitQuestionnaire = async (data: {
  answers: Record<string, number>;
}) => {
  posthog.capture('Questionnaire submitted');
  logFacebook('QuestionnaireSubmitted', true);
  return instance
    .post('questionnaireInstances', {
      searchParams: {
        type: 'phqAds',
      },
      json: data,
    })
    .json();
};

export interface Survey {
  noLongerUse?: number;
  mainBenefit: string;
  howImprove: string;
}

export const submitSurvey = async (data: Survey): Promise<void> =>
  instance.post('survey', { json: data }).json();

export interface CalendarDay {
  date: string;
  entryCompletionCount: number;
  entryCbtCompletionCount?: number;
  entryPpCompletionCount?: number;
  hasFirstEntry: boolean;
  goalStatus: 'Hit' | 'Miss' | 'Pending' | 'None';
}
export interface JournalStats {
  entryCompletionCount: number;
  entryCbtCompletionCount: number;
  entryPpCompletionCount: number;
  hitsRoundedPercentage: number;
}
export const getCalendar = async (searchParams: {
  dateToday?: string;
  dateStart?: string;
  dateEnd?: string;
  formatted?: boolean;
}): Promise<CalendarDay[]> =>
  instance.get('dateAttributes/calendar', { searchParams }).json();

export const getCalendarFormatted = async (searchParams: {
  dateStart?: string;
  dateEnd?: string;
  formatted?: boolean;
}): Promise<{
  dates: CalendarDay[];
  datesKeyed: Record<string, CalendarDay>;
  statsByMonth: Record<string, JournalStats>;
}> =>
  instance
    .get('dateAttributes/calendar', {
      searchParams: { ...searchParams, formatted: true },
    })
    .json();

export interface HitRateMonth {
  isoDate: string;
  hitRatePercent: number;
}
export const getHitChart = async (): Promise<{
  numberOfMonths: number;
  data: HitRateMonth[];
}> => instance.get('dateAttributes/hitChart').json();

export const submitFeedback = async (data: {
  text: string;
  isAnonymous?: boolean;
  email?: string;
  platform: string;
}): Promise<void> => instance.post('feedback', { json: data }).json();

export const saveDevice = async (data: {
  id: string;
  fcmRegistrationToken?: string;
}) => instance.put('device', { json: data }).json();

export const exportDataEmail = async ({
  email,
  format,
  startAt,
  endAt,
}: {
  email?: string;
  format: 'pdf' | 'csv';
  startAt?: string;
  endAt?: string;
}): Promise<void> =>
  instance
    .post('users/exportDataEmail', {
      json: { email, format, startAt, endAt },
    })
    .json();

export const createCheckoutSession = async ({
  priceId,
  cancelUrl,
}: {
  cancelUrl: string;
  priceId: string;
}): Promise<{
  session: {
    url: string;
  };
}> =>
  instance
    .post('subscribers/createCheckoutSession', {
      json: {
        priceId,
        successUrl: `${window.location.origin}/?${searchParamResetQueryCache}=true`,
        cancelUrl,
      },
    })
    .json();

interface StripeProduct {
  id: string;
  metadata: {
    freeTrialDays?: string;
  };
}

interface StripePrice {
  id: string;
  product: string;
  currency: string;
  unit_amount: number;
  unit_amount_decimal: string;
  recurring?: {
    interval: 'month' | 'year' | 'week' | 'day';
    interval_count: number;
  };
  metadata: { nonDiscountedUnitAmount?: string };
}

export const getProductStripe = async (): Promise<{
  product: StripeProduct;
  prices: StripePrice[];
}> => instance.get('subscribers/getProductStripe', {}).json();

export const makeAccessRequest = async (data: {
  email: string;
  requestText: string;
}): Promise<void> => {
  posthog.capture('Access requested');
  return instance
    .post('accessRequest', {
      json: { ...data, platform: Capacitor.getPlatform() },
    })
    .json();
};

export const upsertProfessionalAccess = async (
  data: Partial<ProfessionalAccess>,
): Promise<void> => {
  return instance.put('professionalAccess', { json: data }).json();
};

export const applyPromoCode = async (data: { code: string }) => {
  posthog.capture('Promo code applied');
  return instance.post('subscribers/promoCode', { json: data }).json();
};

export const setTrialReminder = async () => {
  return instance.post('trialReminder').json();
};

export const signUp = async (email: string) => {
  return instance.post('signUp', { json: { email } }).json();
};

const exchangeRateApiKey = '8b015dd483d98247006c6c75';
export const convertCurrencyValue = async (
  value: number,
  baseCode: string,
  targetCode: string,
): Promise<number> => {
  if (baseCode === targetCode) {
    return value;
  }
  const result = await ky
    .get(
      `https://v6.exchangerate-api.com/v6/${exchangeRateApiKey}/pair/${baseCode}/${targetCode}/${value}`,
    )
    .json<{ conversion_result: number }>();
  return result.conversion_result;
};
