import { Box, Flex, Icon, Text, chakra } from '@chakra-ui/react';
import Learn from '@src/assets/learn.svg?react';
import Settings from '@src/assets/settings.svg?react';
import type { FC } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Add from './add.svg?react';
import Home from './home.svg?react';
import Insights from './insights.svg?react';

const ChakraNavLink = chakra(NavLink);

const LinkWrapper = ({
  IconComponent,
  text,
  to,
}: {
  IconComponent: FC<React.ComponentProps<'svg'> & { title?: string }>;
  text: string;
  to: string;
}) => {
  return (
    <ChakraNavLink
      flex={1}
      to={to}
      _hover={{
        textDecoration: 'none',
      }}
    >
      {({ isActive }) => (
        <>
          <Icon
            as={IconComponent}
            color={isActive ? 'action.primary.base' : 'neutral.4'}
            display="inline"
            boxSize="32px"
          />
          <Box
            color={isActive ? 'action.primary.base' : 'neutral.3'}
            fontWeight={isActive ? 'semibold' : 'normal'}
          >
            {text}
          </Box>
        </>
      )}
    </ChakraNavLink>
  );
};

const BottomNavBar: FC = () => {
  const navigate = useNavigate();
  return (
    <Box
      bg="neutral.bg.0"
      pb="env(safe-area-inset-bottom)"
      pl="env(safe-area-inset-left)"
      pr="env(safe-area-inset-right)"
      position="sticky"
      bottom={0}
      zIndex={2}
    >
      <Flex
        as="nav"
        textStyle="popHummingbird"
        color="neutral.3"
        justifyContent="space-around"
        alignItems="flex-end"
        p="centi"
        borderTop="1px"
        borderColor="neutral.9"
        textAlign="center"
      >
        <LinkWrapper IconComponent={Home} to="/" text="Home" />
        <LinkWrapper IconComponent={Learn} to="/learn" text="Learn" />
        <Box
          as="button"
          type="button"
          onClick={() => navigate('/entries')}
          position="relative"
          flex={1}
        >
          <Box
            as="span"
            display="block"
            position="absolute"
            bottom="18px"
            textAlign="center"
            width="100%"
          >
            <Icon
              as={Add}
              display="inline"
              color="action.primary.base"
              boxSize="52px"
            />
          </Box>
          <Text as="span" display="block">
            New entry
          </Text>
        </Box>
        <LinkWrapper IconComponent={Insights} to="/insights" text="Insights" />
        <LinkWrapper IconComponent={Settings} to="/profile" text="Profile" />
      </Flex>
    </Box>
  );
};

export default BottomNavBar;
